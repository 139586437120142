/*------------------------------------------------------------------
[Responsive Stylesheet]

Project     : Juzbi POS
Version     : 1.0
Author      : M.Usama Mashkoor
Contact     : usamamashkoor@gmail.com
-------------------------------------------------------------------*/

@media screen and (max-width: 1400px) {
  
	.food-menus-footer .col > .btn {
		font-size: 16px;
	}
	.btn {
		/* font-size: 13px; */
		/* font-size: 11px; */
		font-size: 12px;
	}
	.food-info-list h6 {
		font-size: 13px;
		/* font-size: 16px; */
		/* font-size: 16px; */
	}
	.food-info-list p {
		font-size: 13px;
		/* font-size: 14px; */
	}
	.food-info-head p,.food-info-head div {
		font-size: 13px;
	}
	.food-cart-calculator button {
		font-size: 24px;
	}
	.food-menus-footer > .row > div > .row > div button {
		font-size: 14px;
	}
	/* .btnPutOnSide{
		font-size: 11px !important;
	} */
	.food-cart-calculator button.text-regular {
		font-size: 16px;
	}
	.food-menus .owl-nav {
		right: 15%;
	}
	.food-menus .owl-theme .owl-nav [class*="owl-"] {
		padding: 0 40px;
	}
	.kitchen-item-head p {
		font-size: 24px;
	}

}

@media screen and (max-width: 1200px) {
  
	.food-menus-footer .col > .btn {
		font-size: 16px;
	}
	.btn {
		font-size: 11px;
		/* font-size: 14px; */
	}
	.food-menus-head button p img {
		width: 40%;
	}
	.food-menus-head button p img.mw-100.pr-3 {
		padding-right: 10px !important;
	}
	.food-menus .owl-nav {
		right: 20%;
	}
	.food-menus .owl-theme .owl-nav [class*="owl-"] {
		padding: 0 20px;
	}
	.food-menus-footer .col > .btn {
		font-size: 13px;
	}
	.food-info-sublist {
		padding-left: 12px;
	}
	.food-info-list h6 {
		font-size: 14px;
	}
	.food-info-list span.position-absolute {
		font-size: 10px;
	}
	h5 {
		font-size: 16px;
	}
	.food-cart-calculator button.text-regular {
		font-size: 14px;
	}
	.food-cart-calculator .form-control {
		font-size: 18px;
	}
	.food-menus-footer > .row > div > .row > div button {
		font-size: 10px;
	}
	.food-menus-footer > .row > div > .row > div:last-child {
		padding-left: 0px;
	}
	.food-navigation p {
		font-size: 7px;
	}
	.food-navigation .btn {
		width: 100% !important;
	}
	.kitchen-item-box .owl-nav {
		left: 0;
		right: 0;
	}
	.food-navigation > p {
		font-size: 10px;
	}

}

@media screen and (max-width: 992px) {
  
  	.container {
		max-width: 960px;
		min-width: 960px;
	}
	.container-fluid {
		max-width: 960px;
		min-width: 960px;
	}

}

@media screen and (max-width: 768px) {

  	

}

@media screen and (max-width: 576px) {

  	

}

@media screen and (min-width: 1400px) {
  
  	.container {
		max-width: 1445px;
	}

}

@media screen and (min-width: 1200px) {
  
  	.container {
		max-width: 1445px;
	}

}